import { Location, LocationStrategy, LOCATION_INITIALIZED, PathLocationStrategy } from "@angular/common";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { APP_INITIALIZER, ErrorHandler, Injectable, Injector, NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "@app/core";
import { AuthInterceptor } from "@app/interceptors/auth.service";
import { CacheInterceptor } from "@app/interceptors/cache.interceptor";
import { PendingChangesGuard } from "@app/interceptors/pending-changes.guard";
import { SharedModule } from "@app/shared";
import { environment } from "@env/environment";
import { TranslateService } from "@ngx-translate/core";
import * as Sentry from "@sentry/browser";
import { CookieModule } from "ngx-cookie";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccountsService } from "./core/services/accounts.service";
import { InitService } from "./core/services/init.service";
import { PermissionsService } from "./core/services/permissions.service";
import { LottieComponent, provideLottieOptions } from "ngx-lottie";
import { isClientSideExecution } from "./core/services/utils";
import { LeavingConfirmationModalModule } from "./shared/leaving-confirmation-modal/leaving-confirmation-modal.module";

export function playerFactory() {
    return import("lottie-web");
}

Sentry.init({
    dsn: environment.sentryDSN,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
    constructor(private http: HttpClient) {}

    handleError(error) {
        if (environment.sentryDSN) {
            Sentry.captureException(error.originalError || error);
        } else {
            console.log(error.originalError || error);
        }
        if (isClientSideExecution() && error.stack) {
            this.http.post(`${environment.baseNodeUrlServices}/api/utils/sentry/partners_console`, { error: error.stack }).subscribe({
                next: (resp: any) => {},
            });
        }
    }
}

export function initApp(initService: InitService) {
    return (): Promise<any> => {
        return initService.init();
    };
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
    return () =>
        new Promise<any>((resolve: any) => {
            const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
            locationInitialized.then(() => {
                const languageToUse: string = "en_GB";

                translate.setDefaultLang(languageToUse);
                translate.use(languageToUse).subscribe({
                    next: () => {},
                    error: (err) => {},
                    complete: () => {
                        resolve(null);
                    },
                });
            });
        });
}

@NgModule({
    declarations: [AppComponent],
    bootstrap: [AppComponent],
    imports: [
        CoreModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        CookieModule.forRoot(),
        MatSnackBarModule,
        FormsModule,
        LottieComponent,
        LeavingConfirmationModalModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [TranslateService, Injector],
            multi: true,
        },
        { provide: MAT_DATE_LOCALE, useValue: "en-GB" },
        InitService,
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [InitService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CacheInterceptor,
            multi: true,
        },
        PermissionsService,
        AccountsService,
        PendingChangesGuard,
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler,
        },
        provideHttpClient(withInterceptorsFromDi()),
        provideLottieOptions({
            player: () => import("lottie-web"),
        }),
    ],
})
export class AppModule {}
