export const environment = {
  baseNodeUrlServices: "https://ws.catappult.io",
  baseUploaderNodeUrlServices: "https://uploader.catappult.io",
  basePortalUrl: "https://catappult.io",
  developersConsoleUrl: 'https://developers.catappult.io',
  catappultTokenCookie: "CAT-TOKEN",
  clientKey: "live_CURXTJUT7RDSZI4JPZXPNYEYXAGBJ2BF",
  domainCookie: ".catappult.io",
  environment: "live",
  name: "production",
  production: true,
  sentryDSN: "https://af72ccc7690d4cd38c1e7e429f9c07e8@error.catappult.io/3",
  timestamp: "1577105009578",
  adminConsoleUrl: 'https://admin.catappult.io',
  withdrawUnderMaintenance: false,
  uploaderUnderMaintenance: false,
};
